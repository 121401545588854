.PowerSelect__Tether {
  /* background-color: #fff; */
  z-index: 2001;
  position: fixed;
  display: inline-block;
  float: left;
}
.css-1pahdxg-control:hover {
  border-color: #c7e9f5 !important;
  box-shadow: 0 0 0 3px #c7e9f5 !important;
  border-radius: none;
}
.css-1pahdxg-control {
  border-color: #c7e9f5 !important;
  box-shadow: 0 0 0 3px #c7e9f5 !important;
  border-radius: none;
}
.modal {
  z-index: 2000;
}

.modal-backdrop {
  z-index: 1000;
}

.modal-xxl {
  max-width: 95% !important;
  margin: 4% 0% 0% 4% !important;
}

.modal-xxl .modal-content .modal-body {
  max-height: 2000px !important;
  
}

/* .modal-xxl .modal-content .modal-header{
  display: none
} */
.modal-xxl .modal-content .modal-footer {
  height: 20px;
  justify-content: center;
  padding: 20px;
}

.app-header .navbar-toggler:hover .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='snow' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}
/* 
 .c-dark-theme{
  color: #e1e1e1;
  background-color: #0a0b18
 }

 .c-dark-theme .c-sidebar {
  color: #e1e1e1;
  background: #2c2c34;
  border: 0;
} */

.triangle {
  /* 
  height: 80%; */
  /* background: url(E:\work\itzone\decorama\public\assets\img\2072.jpg); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  width: 75%;
  position: absolute;
  right: 25%;
  bottom: 0px;
  z-index: 0;
  /* The points are: centered top, left bottom, right bottom */
  clip-path: polygon(100% 10%, 50% 100%, 100% 100%);
}
.loginForm {
  /* background-color: red; */
  margin-top: 7%;
  margin-left: 25%;
  width: 235px;
  height: 420px;
}

@media (max-width: 1000px) {
  .triangle {
    display: none;
  }
  .loginForm {
    margin: auto;

    height: 80%;
  }
}

.react-images__header_button {
  z-index: 1025;
}
